import "./styles.scss"

import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Hero = ({ data }) => {
  const slider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    adaptiveHeight: true,
  }

  const slogan = [
    "Wkomponowana w naturę",
    "Rezydencjalny charakter",
    "Prywatne ogródki",
    "Wysokie mieszkania",
    "Panoramiczne okna",
  ]

  return (
    <section className="investment-hero">
      <div className="container-fluid">
        <div className="investment-hero__wrapper">
          <h2>{data.name} </h2>
          <div className="investment-hero__slider">
            <Slider {...slider}>
              {slogan.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
            </Slider>
          </div>
          <Link to="/projekt-15/">
            powrót <span></span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Hero
