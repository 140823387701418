import "./styles.scss"

import React from "react"

const SoldBlur = () => {
  return (
    <section className="investment-sold-blur">
      <div className="container-fluid">
        <h2>Projekt zakończony</h2>
      </div>
    </section>
  )
}

export default SoldBlur
