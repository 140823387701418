import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

// import OurProjects from "components/OurProjects"
import SectionContact from "components/SectionContact"

import {
  Hero,
  Content,
  Location,
  Docs,
  SoldBlur,
} from "page_components/investment"

const Product = ({ pageContext }) => {
  return (
    <Layout>
      <Breadcrumbs title="Zainwestuj" />
      <Hero data={pageContext.data.data} />
      <Content data={pageContext.data.data} />
      <Location data={pageContext.data.data} />
      <Docs data={pageContext.data.data} />
      {/* <OurProjects data={data?.allWpPortfolio?.nodes} /> */}
      <SectionContact />
      <SoldBlur />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPortfolio(limit: 2, sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPortfolio {
          city
          address
          area
          shortDescription
        }
      }
    }
  }
`

export default Product
