/* eslint-disable react/jsx-no-target-blank */

import "./styles.scss"

import React from "react"

import IcoDownload from "assets/icons/download.svg"
import { withPrefix } from "gatsby"

const Docs = () => {
  return (
    <section className="investment-docs">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <h3>Dokumenty do pobrania</h3>
            <a
              href={withPrefix(
                "/Trust-Finances-Rezydencja-Sikorskiego-teaser-2024-03-2.pdf"
              )}
              target="_blank"
            >
              <img src={IcoDownload} alt="" /> Teaser sprzedażowy
            </a>
            {/* <a
              href={withPrefix(
                "/24_01_29_TRUST_TRUST_INVESTMENT_PROJEKT_11_INFORMACJA_WS_RYZYK.pdf"
              )}
              target="_blank"
            >
              <img src={IcoDownload} alt="" /> Informacja w sprawie ryzyk
            </a> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Docs
