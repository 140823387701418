/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "./styles.scss"

import React from "react"

import InvestPopupForm from "components/InvestPopupForm"

const InvestPopup = ({ popup, setPopup, productPrice, name, productName }) => {
  return (
    popup && (
      <>
        <div className="invest-baner">
          <div className="invest-baner__wrapper">
            <h3>Porozmawiajmy o bezpiecznej inwestycji</h3>
            <p>
              Doskonale rozumiemy potrzebę dogłębnego zapoznania się z ofertą
              oraz jej wszystkimi warunkami. Stawiamy na pełną transparentność,
              dlatego chętnie przedstawimy szczegóły projektu podczas rozmowy
              lub spotkania i odpowiemy na wszystkie pytania.
            </p>
            <button
              className="invest-baner__close"
              onClick={() => setPopup(false)}
            >
              Close
            </button>
            <InvestPopupForm />
          </div>
        </div>
        <div
          className="invest-baner__overlay"
          onClick={() => setPopup(false)}
        />
      </>
    )
  )
}

export default InvestPopup
